import React, { Suspense } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

const CodeComponent = React.lazy(() => import("./code.component"));

export const withCode = (): Plugin => {
  return {
    kind: "code",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      return (
        <Suspense>
          <CodeComponent {...props} />
        </Suspense>
      );
    },
  };
};
