import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps } from "../../core";

export const NumberedList = (props: PropsWithChildren<{}>) => {
  return (
    <ol className="cms_list-decimal cms_list-inside">
      {React.Children.map(props.children, (item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ol>
  );
};

const NumberedListPresenter = (props: JsonNodePresenterProps) => {
  return <NumberedList>{props.children}</NumberedList>;
};

export const withNumberedList = (): Plugin => {
  return {
    kind: "numbered-list",
    presenterSelector: (_) => NumberedListPresenter,
  };
};
