import React, { PropsWithChildren, useMemo } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

const columnMapping: { [key: string]: string } = {
  "1": "md:cms_grid-cols-1",
  "2": "md:cms_grid-cols-2",
  "3": "md:cms_grid-cols-3",
  "4": "md:cms_grid-cols-4",
  "5": "md:cms_grid-cols-5",
};

export const Column = (
  props: PropsWithChildren<{
    space_between?: boolean;
    column_count?: number;
  }>
) => {
  const { space_between = true, column_count = 1 } = props;

  const columnStyle = useMemo<string>(() => {
    const styles = ["cms_grid", "cms_grid-cols-1"];
    if (space_between) {
      styles.push("cms_gap-2.5");
    }
    const clazz = columnMapping[`${column_count}`];
    if (clazz) {
      styles.push(clazz);
    }
    return styles.join(" ");
  }, [column_count, space_between]);

  return <div className={columnStyle}>{props.children}</div>;
};

export const withColumn = (): Plugin => {
  return {
    kind: "column",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      const { p } = node;
      const column_count = useMemo<number>(() => {
        let value = 1;
        if (p) {
          value = p["count"] || 1;
        }
        return value;
      }, [p]);

      return <Column column_count={column_count}>{props.children}</Column>;
    },
  };
};
