import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps, Attributes } from "../../core";

export const Heading2 = (
  props: PropsWithChildren<{ attributes?: Attributes }>
) => {
  const { attributes = {} } = props;
  return <h2 {...attributes}>{props.children}</h2>;
};

export const withH2 = (): Plugin => {
  return {
    kind: "h2",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      return (
        <Heading2 attributes={props.node_meta?.attributes}>
          {props.children}
        </Heading2>
      );
    },
  };
};
