import React, { PropsWithChildren, useMemo } from "react";

export const CardFooter = (
  props: PropsWithChildren<{ show_border?: boolean }>
) => {
  const { children, show_border = false } = props;

  const css = useMemo(() => {
    const styles = ["cms_self-end cms_w-full"];
    if (show_border) {
      styles.push("cms_border-t cms_border-gray-200 cms_pt-4");
    }
    return styles.join(" ");
  }, [show_border]);

  return <div className={css}>{children}</div>;
};
