import React from "react";
import { PresenterContext } from "../../../core";
import { ContentRenderContext } from "./context.type";

const getLocale = (): string => "en_US";

const ContentRenderContextInstance = React.createContext<ContentRenderContext>({
  getLocale,
  getStandardNodePresenter: (kind: string, context: PresenterContext) => null,
  getExtendedNodePresenter: (kind: string, context: PresenterContext) => null,
});

export default ContentRenderContextInstance;
