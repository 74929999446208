import React, { PropsWithChildren, useMemo } from "react";

export const CardHeader = (
  props: PropsWithChildren<{ show_border?: boolean }>
) => {
  const { show_border = false } = props;

  const css = useMemo(() => {
    const styles = ["cms_w-full cms_self-start"];
    if (show_border) {
      styles.push("cms_border-b cms_border-gray-200 cms_py-4");
    }
    return styles.join(" ");
  }, [show_border]);

  return <div className={css}>{props.children}</div>;
};
