import React, { useMemo, useCallback, ReactElement } from "react";
import {
  Plugin,
  Attributes,
  PresenterContext,
  JsonNodePresenterProps,
} from "../../core";

export const TextComponent = (props: {
  text: string;
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
  attributes?: Attributes;
}) => {
  const { bold, italic, underline, attributes = {} } = props;
  const renderText = useCallback(
    (text: string): ReactElement => {
      let comp = <>{text}</>;
      if (bold) {
        comp = <strong>{comp}</strong>;
      }
      if (italic) {
        comp = <em>{comp}</em>;
      }
      if (underline) {
        comp = <u>{comp}</u>;
      }
      return <span {...attributes}>{comp}</span>;
    },
    [bold, italic, underline, attributes]
  );
  return <>{renderText(props.text)}</>;
};

const TextPresenter = (props: JsonNodePresenterProps) => {
  const { node, node_meta } = props;
  const [bold, italic, underline, text] = useMemo(() => {
    const { p: props } = node;
    if (props) {
      const bold = "true" === props["bold"];
      const italic = "true" === props["italic"];
      const underline = "true" === props["underline"];
      return [bold, italic, underline, props["text"]];
    }
    return [false, false, false, null];
  }, [node]);

  return (
    <>
      {text && text.length > 0 ? (
        <TextComponent
          text={text}
          bold={bold}
          italic={italic}
          underline={underline}
          attributes={node_meta?.attributes}
        />
      ) : null}
    </>
  );
};

export const withText = (): Plugin => {
  return {
    kind: "text",
    presenterSelector: (context: PresenterContext) => TextPresenter,
  };
};
