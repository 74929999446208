import React, { PropsWithChildren } from "react";
import { Attributes, JsonNodePresenterProps, Plugin } from "../../core";

export const Heading1 = (
  props: PropsWithChildren<{ attributes?: Attributes }>
) => {
  const { attributes = {} } = props;
  return <h1 {...attributes}>{props.children}</h1>;
};

export const withH1 = (): Plugin => {
  return {
    kind: "h1",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      return (
        <Heading1 attributes={props.node_meta?.attributes}>
          {props.children}
        </Heading1>
      );
    },
  };
};
