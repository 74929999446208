import React, { ReactElement } from "react";
import { PostProps } from "./post.type";

export const PostTitle = (props: {
  children: (props: PostProps) => ReactElement;
}) => {
  return (
    <div className="cms_mb-5 cms_post_title">
      {props.children({ styleClass: "cms_post_title" })}
    </div>
  );
};
