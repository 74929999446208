import React, { PropsWithChildren } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

export const Blockquote = (props: PropsWithChildren<{}>) => {
  return <blockquote>{props.children}</blockquote>;
};

const BlockquotePresenter = (props: JsonNodePresenterProps) => {
  return <Blockquote>{props.children}</Blockquote>;
};

export const withBlockQuote = (): Plugin => {
  return {
    kind: "blockquote",
    presenterSelector: (_) => BlockquotePresenter,
  };
};
