import React, { Children, PropsWithChildren, useMemo } from "react";
import { Attributes, JsonNodePresenterProps, Plugin } from "../../core";

export interface LinkInlineProps {
  url: string;
  underline?: boolean;
  attributes?: Attributes;
}

export const LinkInline = (props: PropsWithChildren<LinkInlineProps>) => {
  const { url, underline = true, attributes = {}, children } = props;

  const classNames = useMemo(() => {
    const names = ["cms_transition cms_duration-300 cms_ease-in-out"];
    if (underline) {
      names.push("cms_underline");
    } else {
      names.push("cms_no-underline");
    }
    return names.join(" ");
  }, [underline]);

  return (
    <a href={url} className={classNames} {...attributes}>
      {children}
    </a>
  );
};

export const withLink = (): Plugin => {
  return {
    kind: "link",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node, node_meta } = props;
      const properties = useMemo<LinkInlineProps | null>(() => {
        const { p } = node;
        if (p) {
          const { url, underline, ..._ } = p;
          const attrs = node_meta?.attributes || {};
          if (url) {
            return {
              url,
              underline,
              ...attrs,
            };
          }
        }
        return null;
      }, [node, node_meta?.attributes]);

      return (
        <>
          {properties ? (
            <LinkInline {...properties}>{props.children}</LinkInline>
          ) : null}
        </>
      );
    },
  };
};
