import React, { useMemo } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

const supportedSizes = {
  "w-12": "cms_w-12",
  "w-16": "cms_w-16",
  "w-24": "cms_w-24",
  "w-32": "cms_w-32",
};

const supportedShapes = {
  square: "cms_rounded-lg",
  circle: "cms_rounded-full",
};

export type AvatarSize = keyof typeof supportedSizes;
export type AvatarShape = keyof typeof supportedShapes;

export interface AvatarProps {
  url: string;
  alt?: string;
  size?: AvatarSize;
  shape?: AvatarShape;
}
export const Avatar = (props: AvatarProps) => {
  const { url, alt = "", size = "w-16", shape = "square" } = props;

  const classNames = useMemo(() => {
    const names = [];
    names.push(supportedShapes[shape] || supportedShapes["square"]);
    names.push(supportedSizes[size] || supportedSizes["w-16"]);
    return names.join(" ");
  }, [size, shape]);

  return <img src={url} className={classNames} alt={alt} />;
};

export const withAvatar = (): Plugin => {
  return {
    kind: "avatar",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      const properties = useMemo<AvatarProps | null>(() => {
        const { p, l } = node;
        if (p) {
          const { url, alt = null, size, shape } = p;
          if (url) {
            return {
              url,
              alt,
              size,
              shape,
            };
          }
        }
        return null;
      }, [node]);
      return <>{properties ? <Avatar {...properties} /> : null}</>;
    },
  };
};
