import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps, Attributes } from "../../core";

export const Paragraph = (
  props: PropsWithChildren<{ attributes?: Attributes }>
) => {
  const { attributes = {} } = props;
  return <p {...attributes}>{props.children}</p>;
};

const ParagraphPresenter = (props: JsonNodePresenterProps) => {
  return (
    <Paragraph attributes={props.node_meta?.attributes}>
      {props.children}
    </Paragraph>
  );
};

export const withParagraph = (): Plugin => {
  return {
    kind: "paragraph",
    presenterSelector: (context) => ParagraphPresenter,
  };
};
