export enum PaddingCss {
  Default = "cms_p-4",
  None = "cms_p-0",
  P1 = "cms_p-1",
  P1_5 = "cms_p-1.5",
  P2 = "cms_p-2",
  P2_5 = "cms_p-2.5",
  P3 = "cms_p-3",
  P3_5 = "cms_p-3.5",
}

export enum PaddingXCss {
  Default = "cms_px-4",
  None = "cms_px-0",
  P1 = "cms_px-1",
  P1_5 = "cms_px-1.5",
  P2 = "cms_px-2",
  P2_5 = "cms_px-2.5",
  P3 = "cms_px-3",
  P3_5 = "cms_px-3.5",
}

export enum PaddingYCss {
  Default = "cms_py-4",
  None = "cms_py-0",
  P1 = "cms_py-1",
  P1_5 = "cms_py-1.5",
  P2 = "cms_py-2",
  P2_5 = "cms_py-2.5",
  P3 = "cms_py-3",
  P3_5 = "cms_py-3.5",
}

export type KeyType<T> = {
  [key in keyof T]: boolean;
};

export type Padding = keyof KeyType<typeof PaddingCss>;
export type PaddingX = keyof KeyType<typeof PaddingXCss>;
export type PaddingY = keyof KeyType<typeof PaddingYCss>;

export type ClassName = PaddingCss | PaddingXCss | PaddingYCss | string;
