import {
  withBlockQuote,
  withBulletedList,
  withColumn,
  withContainer,
  withH1,
  withH2,
  withH3,
  withH4,
  withImage,
  withListItem,
  withNumberedList,
  withParagraph,
  withRow,
  withText,
  withCode,
  withVideo,
  withLink,
  withAvatar,
  withNoteBox,
  withDocument,
} from "../../component";
import { withEmbedded } from "../../component/embedded_links";
import { Plugin } from "../../core";

export const getStarterPlugins = (): Plugin[] => [
  withDocument(),
  withH1(),
  withH2(),
  withH3(),
  withH4(),
  withImage(),
  withText(),
  withParagraph(),
  withBlockQuote(),
  withListItem(),
  withNumberedList(),
  withBulletedList(),
  withColumn(),
  withContainer(),
  withRow(),
  withCode(),
  withVideo(),
  withLink(),
  withAvatar(),
  withNoteBox(),
  withEmbedded(),
];
