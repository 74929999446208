import React, { useMemo } from "react";
import { JsonNodePresenterProps, Plugin } from "../../../core";
import { EmbeddedGist } from "../gist";
import { gist_helpers } from "../gist/gist.helper";

export const withEmbedded = (): Plugin => {
  return {
    kind: "embedded",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      const { node } = props;

      const embedded_url = useMemo(() => {
        const { p } = node;
        if (p) {
          const { url = null } = p;
          if (url) {
            return url;
          }
        }
      }, [node]);

      return (
        <>
          {embedded_url ? (
            gist_helpers.isGistUrl(embedded_url) ? (
              <EmbeddedGist gist_url={embedded_url} />
            ) : null
          ) : null}
        </>
      );
    },
  };
};
