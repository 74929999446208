import React from "react";
import { Plugin, JsonNodePresenterProps } from "../../core";

export const withListItem = (): Plugin => {
  return {
    kind: "list-item",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      // bulleted-list and numbered-list have already append the <li> for its children so here is just to render the children component
      return <>{props.children}</>;
    },
  };
};
