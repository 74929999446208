import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps, Attributes } from "../../core";

export const Heading3 = (
  props: PropsWithChildren<{ attributes?: Attributes }>
) => {
  const { attributes = {} } = props;
  return <h3 {...attributes}>{props.children}</h3>;
};

const H3Presenter = (props: JsonNodePresenterProps) => {
  return (
    <Heading3 attributes={props.node_meta?.attributes}>
      {props.children}
    </Heading3>
  );
};

export const withH3 = (): Plugin => {
  return {
    kind: "h3",
    presenterSelector: (context) => H3Presenter,
  };
};
