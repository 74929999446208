import React from "react";
import { JsonNodePresenter } from "../../content";
import { JsonNodePresenterProps, Plugin } from "../../core";

export const withDocument = (): Plugin => {
  return {
    kind: "doc",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node, node_meta, context } = props;
      return (
        <>
          {node.c && node.c.length > 0 ? (
            <>
              {node.c.map((node, index) => (
                <JsonNodePresenter
                  node={node}
                  node_meta={node_meta}
                  context={context}
                  key={index}
                />
              ))}
            </>
          ) : null}
        </>
      );
    },
  };
};
