import React, { useMemo } from "react";
import { Plugin, JsonNodePresenterProps, Attributes } from "../../core";

export const ImageComponent = (props: {
  url: string;
  alt: string;
  width?: string;
  height?: string;
  attributes?: Attributes;
}) => {
  const { width, height, attributes } = props;

  const imageProps = useMemo(() => {
    const properties: Attributes = {};
    if (attributes) {
      for (const key in attributes) {
        properties[key] = attributes[key];
      }
    }

    if (width) {
      properties.width = width;
    }
    if (height) {
      properties.height = height;
    }

    const styles = ["cms_max-w-full cms_h-auto cms_m-0"];
    if (properties["className"]) {
      styles.push(properties["className"]);
    }
    properties["className"] = styles.join(" ");
    return properties;
  }, [width, height, attributes]);

  return <img src={props.url} alt={props.alt} {...imageProps}></img>;
};

const EXCLUDED_LIST = ["url", "alt"];

export const withImage = (): Plugin => {
  return {
    kind: "image",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      const { node, node_meta } = props;
      const imageProps = useMemo(() => {
        const { p: props } = node;
        if (props) {
          return Object.keys(props)
            .filter((it) => !EXCLUDED_LIST.includes(it))
            .reduce<{ [key: string]: string }>((listProps, key) => {
              listProps[key] = props[key];
              return listProps;
            }, {});
        }
        return {};
      }, [node]);
      return (
        <ImageComponent
          url={node.p!!["url"]}
          alt={node.p!!["alt"]}
          {...imageProps}
          attributes={node_meta?.attributes}
        />
      );
    },
  };
};
