import React, { ReactElement } from "react";
import { PostProps } from "./post.type";

export const PostSubTitle = (props: {
  children: (props: PostProps) => ReactElement;
}) => {
  return (
    <div className="cms_lead cms_post_subtitle cms_mb-9">
      {props.children({ styleClass: "cms_lead cms_post_subtitle" })}
    </div>
  );
};
