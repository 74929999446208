import React from "react";

const PulseLine = React.memo(() => (
  <div className="cms_grid cms_grid-cols-4 cms_gap-2">
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-1"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-3"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-1"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-3"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-1"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-3"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-1"></div>
    <div className="cms_h-2 cms_bg-slate-300 cms_rounded cms_col-span-3"></div>
  </div>
));

export const CardPulse = React.memo((props: {}) => {
  return (
    <div className="cms_animate-pulse">
      <div className="cms_py-2.5">
        <div className="cms_h-2 cms_bg-slate-300 cms_rounded"></div>
      </div>
      <div className="cms_space-y-3">
        <PulseLine />
      </div>
    </div>
  );
});
