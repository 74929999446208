import React, { PropsWithChildren, useMemo } from "react";

export const Prose = (
  props: PropsWithChildren<{
    maxWidth?: boolean;
  }>
) => {
  const { maxWidth = false } = props;
  const styleNames = useMemo<string>(() => {
    const names: string[] = ["cms_prose", "cms_prose-slate"];
    if (maxWidth) {
      names.push("cms_max-w-none");
    }
    return names.join(" ");
  }, [maxWidth]);
  return <div className={styleNames}>{props.children}</div>;
};
