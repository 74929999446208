import React, { PropsWithChildren, useMemo } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

export const Container = (
  props: PropsWithChildren<{
    center?: boolean;
  }>
) => {
  const { center = false } = props;
  const styleClasses = useMemo(() => {
    const clazzes = ["cms_container", "cms_p-4 md:cms_p-0"];
    if (center) {
      clazzes.push("cms_mx-auto");
    }
    return clazzes.join(" ");
  }, [center]);

  return <div className={styleClasses}>{props.children}</div>;
};

export const withContainer = (): Plugin => {
  return {
    kind: "container",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      return <Container>{props.children}</Container>;
    },
  };
};
