import React, { PropsWithChildren, useMemo } from "react";
import { Plugin, PresenterContext } from "../../core";

export const Row = (props: PropsWithChildren<{}>) => {
  const rowStyle = useMemo<string>(() => {
    const styles = [
      "cms_flex",
      "cms_flex-col",
      "cms_space-y-2.5",
      "md:cms_flex-row",
      "md:cms_space-x-2.5",
      "md:cms_space-y-0",
      "cms_items-start",
    ];
    return styles.join(" ");
  }, []);
  return <div className={rowStyle}>{props.children}</div>;
};

export const withRow = (): Plugin => {
  return {
    kind: "row",
    presenterSelector: (_: PresenterContext) => (props) => {
      return <Row>{props.children}</Row>;
    },
  };
};
