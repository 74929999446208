import React, { PropsWithChildren, useMemo } from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

const colorMapping = {
  info: "cms_bg-gray-100",
  alert: "cms_bg-red-100",
  warning: "cms_bg-yellow-100",
  success: "cms_bg-green-100",
  default: "cms_bg-blue-100",
};

export type BoxLevel = keyof typeof colorMapping;

export const NoteBox = (props: PropsWithChildren<{ level?: BoxLevel }>) => {
  const { level = "default" } = props;
  const styleNames = useMemo(() => {
    const names = ["cms_rounded-lg", "cms_py-5", "cms_px-6", "cms_mb-4"];
    names.push(colorMapping[level] || colorMapping["default"]);
    return names.join(" ");
  }, [level]);

  return <div className={styleNames}>{props.children}</div>;
};

export const withNoteBox = (): Plugin => {
  return {
    kind: "note",
    presenterSelector: (_) => (props: JsonNodePresenterProps) => {
      const { node } = props;
      return <NoteBox {...(node.p || {})}>{props.children}</NoteBox>;
    },
  };
};
