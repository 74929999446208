import React from "react";
import { JsonNodePresenterProps, Plugin } from "../../core";

export const withImageCarousel = (): Plugin => {
  return {
    kind: "image-carousel",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      return <div></div>;
    },
  };
};
