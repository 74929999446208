import React, { useEffect, useMemo, useState } from "react";

export const EmbeddedGist = (props: { gist_url: string }) => {
  const { gist_url } = props;
  const [iframeRef, setIframeRef] = useState<HTMLIFrameElement | null>(null);

  const gist_id = useMemo(() => {
    return gist_url.split("/").pop();
  }, [gist_url]);

  useEffect(() => {
    if (iframeRef) {
      const { contentDocument = null, contentWindow = null } = iframeRef;
      let doc;
      if (contentDocument) {
        doc = contentDocument;
      } else if (contentWindow) {
        doc = contentWindow.document;
      }
      if (doc) {
        const resizeScript = `onload="parent.document.getElementById('${gist_id}').style.height=document.body.scrollHeight + 'px'"`;
        const iframeHtml = `<html><head><base target="_parent"></head><body ${resizeScript}><script type="text/javascript" src="${gist_url}"></script></body></html>`;
        doc.open();
        doc.writeln(iframeHtml);
        doc.close();
      }
    }
  }, [iframeRef]);

  return (
    <iframe
      ref={setIframeRef}
      style={{ width: "100%", border: 0 }}
      id={gist_id}
    />
  );
};
