import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps } from "../../core";

export const BulletedList = (props: PropsWithChildren<{}>) => {
  return (
    <ul className="cms_list-disc cms_list-inside">
      {React.Children.map(props.children, (item, index) => {
        return <li key={index}>{item}</li>;
      })}
    </ul>
  );
};

const BulletedListPresenter = (props: JsonNodePresenterProps) => {
  return <BulletedList>{props.children}</BulletedList>;
};

export const withBulletedList = (): Plugin => {
  return {
    kind: "bulleted-list",
    presenterSelector: (_) => BulletedListPresenter,
  };
};
