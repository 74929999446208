import { PluginRegistry, Plugin } from "./plugin.type";

const initPluginRegistry = (plugins: Plugin[]): PluginRegistry => {
  return plugins.reduce<PluginRegistry>((registry, plugin) => {
    registry[plugin.kind] = plugin;
    return registry;
  }, {});
};

export default {
  initPluginRegistry,
};
