import React, { PropsWithChildren } from "react";

export const CardBody = (
  props: PropsWithChildren<{ doOnClick?: () => void; className?: string }>
) => {
  const { doOnClick, className = "" } = props;
  return (
    <div
      className={`cms_w-full cms_py-4 ${className}`}
      onClick={(e) => {
        if (doOnClick) {
          e.preventDefault();
          doOnClick();
        }
      }}
    >
      {props.children}
    </div>
  );
};
