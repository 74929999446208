import React, { PropsWithChildren, useMemo } from "react";
import { ClassName } from "../../css";

export const Card = (
  props: PropsWithChildren<{
    show_border?: boolean;
    border_rounded?: boolean;
    hover_effect?: boolean;
    class_names?: ClassName[];
  }>
) => {
  const {
    show_border = true,
    hover_effect = false,
    border_rounded = false,
    class_names,
  } = props;

  const styles = useMemo(() => {
    const names = ["cms_flex cms_flex-col cms_p-4"];
    if (hover_effect) {
      names.push("hover:cms_bg-gray-100");
    }
    if (show_border) {
      names.push("cms_border", "cms_border-gray-200");
    }
    if (border_rounded) {
      names.push("cms_rounded-md");
    }
    if (class_names && class_names.length > 0) {
      names.push(class_names.join(" "));
    }
    return names.join(" ");
  }, [show_border, hover_effect, border_rounded, class_names]);

  return <div className={styles}>{props.children}</div>;
};
