import React, { PropsWithChildren, useCallback, useMemo } from "react";
import { getStarterPlugins } from "../../../misc/content.misc";
import {
  PluginRegistry,
  Plugin,
  PluginDecoratorFunc,
  PluginHelper,
  PresenterContext,
  PresentMode,
} from "../../../../core";
import {
  ContentRenderContext,
  ContentRenderContextInstance,
} from "../../context";

const standardRegistry = PluginHelper.initPluginRegistry(getStarterPlugins());

export type ContentPresenterZoneProps = PropsWithChildren<{
  plugins?: Plugin[];
  mode?: PresentMode;
  plugin_decorator?: PluginDecoratorFunc;
}>;

export const ContentPresenterZone = (props: ContentPresenterZoneProps) => {
  const { children, plugins, mode, plugin_decorator } = props;

  const extendedRegistry = useMemo<PluginRegistry>(() => {
    return plugins ? PluginHelper.initPluginRegistry(plugins) : {};
  }, [plugins]);

  const decorator: PluginDecoratorFunc = useMemo(() => {
    if (plugin_decorator) {
      return plugin_decorator;
    }
    return (plugin) => plugin;
  }, [plugin_decorator]);

  const decorate = (plugin?: Plugin): Plugin | undefined => {
    if (plugin) {
      return decorator(plugin);
    }
    return plugin;
  };

  const getNodePresenter = useCallback(
    (kind: string, context: PresenterContext, registry: PluginRegistry) => {
      const plugin = decorate(registry[kind]);
      if (plugin) {
        return plugin.presenterSelector(context);
      }
    },
    []
  );

  const renderContext = useMemo<ContentRenderContext>(() => {
    return {
      getStandardNodePresenter: (kind: string, context: PresenterContext) =>
        getNodePresenter(kind, context, standardRegistry),
      getExtendedNodePresenter: (kind: string, context: PresenterContext) =>
        getNodePresenter(kind, context, extendedRegistry),
      mode,
    };
  }, [plugins]);

  return (
    <ContentRenderContextInstance.Provider value={renderContext}>
      {children}
    </ContentRenderContextInstance.Provider>
  );
};
