import React from "react";
import { ReactNode } from "react";

import { JsonNode, JsonNodeMeta, PresenterContext } from "../../../../core";
import { JsonPresenterHelper, useJsonPresenterHelper } from "../../hook";

const renderFunc = (
  nodePresenterHelper: JsonPresenterHelper,
  node: JsonNode,
  context: PresenterContext,
  node_meta?: JsonNodeMeta
): ReactNode => {
  const { c: children, k: kind } = node;
  const PresenterFunc = nodePresenterHelper.getJsonNodePresenter(kind, context);
  if (PresenterFunc) {
    return (
      <PresenterFunc node={node} node_meta={node_meta} context={context}>
        {children && children.length > 0
          ? children.map((item, index) => (
              <JsonNodePresenter
                node={item}
                key={index}
                node_meta={node_meta}
                context={context}
              />
            ))
          : null}
      </PresenterFunc>
    );
  }
  return null;
};

export const JsonNodePresenter = (props: {
  node: JsonNode;
  context: PresenterContext;
  node_meta?: JsonNodeMeta;
}) => {
  const nodePresenterHelper = useJsonPresenterHelper();
  const { node, node_meta, context } = props;
  return <>{renderFunc(nodePresenterHelper, node, context, node_meta)}</>;
};
