import React, { useMemo } from "react";
import {
  Attributes,
  JsonNodePresenterProps,
  Plugin,
  PresenterContext,
} from "../../core";

const aspectRatios = {
  "16/9": "cms_aspect-w-16 cms_aspect-h-9",
};

export type VideoRatio = keyof typeof aspectRatios;

export const EmbeddedVideo = (props: {
  ratio?: VideoRatio;
  url: string;
  title?: string;
  autoplay?: boolean;
  attributes?: Attributes;
}) => {
  const {
    url,
    ratio = "16/9",
    title,
    autoplay = false,
    attributes = {},
  } = props;

  const { className = null, ...rest_attrs } = attributes;

  const classNames = useMemo(() => {
    const names = [];
    names.push(aspectRatios[ratio] || "16/9");
    if (className) {
      names.push(className);
    }
    return names.join(" ");
  }, [ratio, className]);

  const allow = useMemo(() => {
    const allowOptions = [
      "accelerometer;",
      "clipboard-write;",
      "encrypted-media;",
      "picture-in-picture;",
    ];
    if (autoplay) {
      allowOptions.push("autoplay;");
    }
    return allowOptions.join(" ");
  }, [autoplay]);

  return (
    <div className={classNames} {...rest_attrs}>
      <iframe
        src={url}
        style={{ border: 0 }}
        title={title}
        allow={allow}
        allowFullScreen
      ></iframe>
    </div>
  );
};

const embeddedType = "embedded";
const defaultType = embeddedType;
const supportedTypes = [defaultType];

const VideoPresenter = (props: JsonNodePresenterProps) => {
  const { node, node_meta } = props;
  const { p: properties = {} } = node;
  const { url, ...rest } = properties;
  const type = useMemo(() => {
    const { p: properties = {} } = node;
    const type = properties["type"] || defaultType;
    if (supportedTypes.includes(type)) {
      return type;
    }
    return defaultType;
  }, [node]);

  return (
    <>
      {url && type === embeddedType ? (
        <EmbeddedVideo url={url} {...rest} attributes={node_meta?.attributes} />
      ) : null}
    </>
  );
};

export const withVideo = (): Plugin => {
  return {
    kind: "video",
    presenterSelector: (context: PresenterContext) => VideoPresenter,
  };
};
