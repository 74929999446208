import { useContext } from "react";
import { JsonNodePresenterFunc, PresenterContext } from "../../../core";
import { ContentRenderContextInstance } from "../context";

export interface JsonPresenterHelper {
  getJsonNodePresenter: (
    kind: string,
    context: PresenterContext
  ) => JsonNodePresenterFunc | null;
}

export const useJsonPresenterHelper = (): JsonPresenterHelper => {
  const contextInstance = useContext(ContentRenderContextInstance);
  const getJsonNodePresenter = (
    kind: string,
    context: PresenterContext
  ): JsonNodePresenterFunc | null => {
    return (
      contextInstance.getStandardNodePresenter(kind, context) ||
      contextInstance.getExtendedNodePresenter(kind, context) ||
      null
    );
  };
  return {
    getJsonNodePresenter,
  };
};
