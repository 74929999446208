import React, { PropsWithChildren } from "react";
import { Plugin, JsonNodePresenterProps, Attributes } from "../../core";

export const Heading4 = (
  props: PropsWithChildren<{ attributes?: Attributes }>
) => {
  const { attributes = {} } = props;
  return <h4 {...attributes}>{props.children}</h4>;
};

export const withH4 = (): Plugin => {
  return {
    kind: "h4",
    presenterSelector: (context) => (props: JsonNodePresenterProps) => {
      return (
        <Heading4 attributes={props.node_meta?.attributes}>
          {props.children}
        </Heading4>
      );
    },
  };
};
